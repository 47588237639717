import { takeEvery, put, call, all } from 'redux-saga/effects'
import { login } from './actions'
import * as authService from '../../services/authService'

function* loginUser({ payload }) {
  try {
    yield put(login.request())
    const user = yield call(authService.login, payload)

    yield put(login.success(user))
  } catch (error) {
    yield put(login.failure(error.message))
  } finally {
    yield put(login.fulfill())
  }
}

function* watchLogin() {
  yield takeEvery(login.TRIGGER, loginUser)
}

export default function* loginSaga() {
  yield all([watchLogin()])
}
