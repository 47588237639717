import { takeEvery, put, all, call } from 'redux-saga/effects'
import * as authService from '../../services/authService'
import { getUser, telegramLogin, logout, dropUser } from './actions'

function* fetchUser() {
  try {
    yield put(getUser.request())
    const user = yield call(authService.fetchUser)

    yield put(getUser.success(user))
  } catch (error) {
    yield put(getUser.failure(error.message))
  } finally {
    yield put(getUser.fulfill())
  }
}

function* watchFetchUser() {
  yield takeEvery(getUser.TRIGGER, fetchUser)
}

function* signIn({ payload }) {
  try {
    yield put(telegramLogin.request())
    const user = yield call(authService.telegramAuth, payload)

    yield put(telegramLogin.success(user))
  } catch (error) {
    yield put(telegramLogin.failure(error.message))
  } finally {
    yield put(telegramLogin.fulfill())
  }
}

function* watchSignIn() {
  yield takeEvery(telegramLogin.TRIGGER, signIn)
}

function* signOut() {
  try {
    yield put(logout.request())
    const result = yield call(authService.logout)

    if (result) {
      yield put(dropUser.request())
    }
  } catch (error) {
    yield put(logout.failure(error.message))
  }
}

function* watchSignout() {
  yield takeEvery(logout.TRIGGER, signOut)
}

export default function* userSaga() {
  yield all([watchFetchUser(), watchSignIn(), watchSignout()])
}
