import { takeEvery, put, all, call } from 'redux-saga/effects'
import { getUserEvents } from './actions'
import * as userService from '../../services/userService'
import normalize from '../../helpers/normalize'

function* fetchUserEvents({ payload }) {
  try {
    yield put(getUserEvents.request())

    const { events } = yield call(userService.getUserEvents, payload)
    const normalized = normalize(events)
    yield put(getUserEvents.success(normalized))
  } catch (error) {
    yield put(getUserEvents.failure(error.message))
  } finally {
    yield put(getUserEvents.fulfill())
  }
}

function* watchFetchUserEvents() {
  yield takeEvery(getUserEvents.TRIGGER, fetchUserEvents)
}

export default function* userEventsSaga() {
  yield all([watchFetchUserEvents()])
}
