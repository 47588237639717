import callWebApi from '../helpers/callWebApi'

export const getEvents = async () => {
  const response = await callWebApi({
    url: '/events',
    method: 'GET',
  })

  const { data } = response
  return data
}

export const getEvent = async (id) => {
  const response = await callWebApi({
    url: `/events/${id}`,
    method: 'GET',
  })

  const { data } = response
  return data
}

export const addParticipant = async (eventId, userId) => {
  const response = await callWebApi({
    url: `/events/${eventId}`,
    method: 'PATCH',
    data: {
      userId,
    },
  })
  return response.data
}
