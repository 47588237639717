import callWebApi from '../helpers/callWebApi'

export const getUserOverview = async (id) => {
  const response = await callWebApi({
    url: `/users/${id}`,
    method: 'GET',
  })
  const { data } = response
  return data
}

export const getUserEvents = async (id) => {
  const response = await callWebApi({
    url: `/users/${id}/events`,
    method: 'GET',
  })
  const { data } = response
  return data
}

export const checkIn = async (userId, venueId, eventId) => {
  const response = await callWebApi({
    url: `/users/${userId}/check-in`,
    method: 'POST',
    data: {
      userId,
      venueId,
      eventId,
    },
  })
  return response.status === 201
}
