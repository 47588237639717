export default (data) => {
  const byId = {}
  const allIds = []

  data.forEach((element) => {
    const { id } = element
    byId[id] = element
    allIds.push(id)
  })

  return {
    byId,
    allIds,
  }
}
