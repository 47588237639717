import { getEvent, dropEvent } from './actions'

const initialState = {
  isLoading: false,
  error: null,
  entity: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case getEvent.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case getEvent.SUCCESS:
      return {
        ...state,
        entity: action.payload,
      }
    case getEvent.FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case getEvent.FULFILL:
      return {
        ...state,
        isLoading: false,
      }
    case dropEvent.TRIGGER:
      return initialState
    default:
      return state
  }
}
