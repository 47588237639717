import { all } from 'redux-saga/effects'
import eventsSaga from '../containers/Events/saga'
import eventSaga from '../containers/Event/saga'
import userSaga from '../containers/Profile/saga'
import loginSaga from '../containers/Login/saga'
import userEventsSaga from '../containers/UserEvents/saga'

export default function* rootSaga() {
  yield all([
    eventsSaga(),
    eventSaga(),
    userSaga(),
    loginSaga(),
    userEventsSaga(),
  ])
}
