import { takeEvery, put, all, call } from 'redux-saga/effects'
import * as eventsService from '../../services/eventsService'
import { getEvent, registerForEvent } from './actions'

function* fetchEvent({ payload }) {
  try {
    yield put(getEvent.request())
    const event = yield call(eventsService.getEvent, payload)

    yield put(getEvent.success(event))
  } catch (error) {
    yield put(getEvent.failure(error.message))
  } finally {
    yield put(getEvent.fulfill())
  }
}

function* watchFetchEvent() {
  yield takeEvery(getEvent.TRIGGER, fetchEvent)
}

function* registerForEventOnServer({ payload }) {
  const { eventId, userId } = payload
  try {
    yield put(registerForEvent.request())
    yield call(eventsService.addParticipant, eventId, userId)
    yield put(getEvent.trigger(eventId))
  } catch (error) {
    yield put(registerForEvent.failure(error.message))
  }
}

function* watchRegisterForEvent() {
  yield takeEvery(registerForEvent.TRIGGER, registerForEventOnServer)
}

export default function* userSaga() {
  yield all([watchFetchEvent(), watchRegisterForEvent()])
}
