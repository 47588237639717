import callWebApi from '../helpers/callWebApi'

export const fetchUser = async () => {
  const { data } = await callWebApi({
    url: '/auth/user',
    method: 'GET',
  })
  return data
}

export const telegramAuth = async (user) => {
  const { data } = await callWebApi({
    url: '/auth/telegram',
    method: 'GET',
    params: user,
  })
  return data
}

export const logout = async () => {
  const response = await callWebApi({
    url: '/auth/logout',
    method: 'POST',
  })
  return response.status === 200
}

export const login = async (payload) => {
  const { data } = await callWebApi({
    url: 'auth/login',
    method: 'POST',
    data: payload,
  })
  return data
}
