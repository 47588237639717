import axios from 'axios'

axios.defaults.baseURL = process.env.GATSBY_API_URL

export default async function callWebApi(config) {
  try {
    const token = localStorage.getItem('accessToken')
    const requestConfig = {
      ...config,
      headers: { 'Content-Type': 'application/json', crossDomain: true },
      withCredentials: true,
    }
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`
    }

    return axios(requestConfig)
  } catch (error) {
    console.error(error.message)
    return null
  }
}
