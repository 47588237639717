import { getUser, telegramLogin, dropUser } from './actions'
import { login } from '../Login/actions'

const initialState = {
  isLoading: false,
  error: null,
  isAuthenticated: false,
  entity: {
    telegram: {},
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case getUser.REQUEST:
    case telegramLogin.REQUEST:
    case login.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case getUser.SUCCESS:
    case telegramLogin.SUCCESS:
    case login.SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isAuthenticated: true,
        error: null,
      }
    case getUser.FAILURE:
    case telegramLogin.FAILURE:
    case login.FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case getUser.FULFILL:
    case telegramLogin.FULFILL:
    case login.FULFILL:
      return {
        ...state,
        isLoading: false,
      }
    case dropUser.REQUEST:
      return initialState
    default:
      return state
  }
}
