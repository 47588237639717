import { getEvents } from './actions'

const initialState = {
  isLoading: true,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case getEvents.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case getEvents.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case getEvents.FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case getEvents.FULFILL:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
