import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewareEnhancer = applyMiddleware(sagaMiddleware)

const store = createStore(rootReducer, composeWithDevTools(middlewareEnhancer))

export default store

sagaMiddleware.run(rootSaga)
