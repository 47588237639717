import { takeEvery, put, all, call } from 'redux-saga/effects'

import { getEvents } from './actions'
import * as eventsService from '../../services/eventsService'
import normalize from '../../helpers/normalize'

function* fetchEvents() {
  try {
    yield put(getEvents.request())

    const events = yield call(eventsService.getEvents)
    const normalized = normalize(events)
    yield put(getEvents.success(normalized))
  } catch (error) {
    yield put(getEvents.failure(error.message))
  } finally {
    yield put(getEvents.fulfill())
  }
}

function* watchFetchEvents() {
  yield takeEvery(getEvents.TRIGGER, fetchEvents)
}

export default function* eventsSaga() {
  yield all([watchFetchEvents()])
}
